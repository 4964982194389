// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://ecom-backend-mkti.onrender.com/",
});

export default axiosInstance;

export const weburl = "https://ecom-backend-mkti.onrender.com/";
